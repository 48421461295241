<script src="../tools/wxapp_rsa.js"></script>
<template>
  <div id="mainPage">
    <div class="topBC">
      <img src="../static/images/icon_charge_title.png" width="55%" height="25px"/>
      <div class="title">
        <span>用户充值</span>
        <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
        <em v-if="false" class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
      </div>
    </div>

    <!--预约内容区域-->
    <div class="context">
      <div class="title">
        会员协议及会籍条款
      </div>
      <div class="content">
        <p>1. 请您审阅本《会员协议及会籍条款》（以下简称本协议）之内容，充分理解并接受所有条款。 </p>
        <p>2. 请您向医生进行全面咨询，以确认您孩子的健康状况是否适合进行体育锻炼、确认无基础及运动损伤疾病。您需如实向英泓小飞龙提供真实信息。在必要的情况下，英泓小飞龙有权要求您出示相应证明。</p>
        <p>3. 相关之会员权利义务均以本协议约定为准。 </p>
        <p class="title_2">3.1 关于入会</p>
        <p class="title_3">3.1.1 会员取得英泓小飞龙会籍需通过入会评测。 </p>
        <p class="title_3">3.1.2 年龄6至16周岁，以公历生日计。 </p>
        <p class="title_3">3.1.3 会籍生效日为付款日。 </p>
        <p class="title_2">3.2 会籍期内可享受以下服务</p>
        <p class="title_3">3.2.1 线上自主跟练课程 </p>
        <p class="title_3">3.2.2 线上跟练日常训练计划的制定和推荐 </p>
        <p class="title_3">3.2.3 线上直播课程 </p>
        <p class="title_3">3.2.4 充值课时后，可按学员测评结果进行线下课程。 </p>
        <p class="title_3">3.2.5 如遇退费情况，按月计算，退还未使用月份的会籍费。 </p>
        <p class="title_2">3.3 关于课时费 </p>
        <p class="title_3">3.3.1 根据课程设置不同，每节课按相应课时扣费。 </p>
        <p class="title_3">3.3.2 自【预约课程】的课程列表中可查看单课程扣费标准。</p>
        <p class="title_3">3.3.3 充值后，先消耗正常课时，再消耗赠送课时。</p>
        <p class="title_3">3.3.4 如遇退费情况，退还未使用的正常课时，赠送课时不退。</p>
        <p class="title_3">3.3.5 课程开课前45分钟自行预约，课程开课前70分钟自行取消。不取消正常扣相应课时。</p>
        <p>4. 免责声明 </p>
        <p class="title_2">4.1 场馆训练中履行应尽的教育管理职责，尽可能保护学员不产生身体损伤，但无法做到完全避免。 </p>
        <p class="title_2">4.2 线上自主跟练课、直播课的安全保护由学员或其监护人负责。 </p>
        <p class="title_2">4.3 下课后学员应尽快离开培训机构。无故在机构内滞留、嬉戏玩耍造成伤害的，场馆方不承担赔偿责任。 </p>
        <p class="title_2">4.4 训练期间学员应服从教练员及管理人员安排。因不服从管理出现意外后果的，训练方不承担责任。 </p>
        <p class="title_2">4.5 学员在开始培训及结束培训时，英泓小飞龙会通过微信消息通知学员或其监护人。由此英泓小飞龙不再为其他意外承担责任。 </p>
        <p class="title_2">4.6 最终解释权归英泓小飞龙所有。 </p>
        <p class="title">会籍费说明 </p>
        <p>1. 关于入会 </p>
        <p class="title_2">1.1 会员取得英泓小飞龙会籍需通过入会评测。</p>
        <p class="title_2">1.2 年龄6至16周岁，以公历生日计。</p>
        <p class="title_2">1.3 会籍生效日为付款日。</p>
        <p>2. 会籍期内可享受以下服务 </p>
        <p class="title_2">2.1 线上自主跟练课程。</p>
        <p class="title_2">2.2 线上跟练日常训练计划的制定和推荐。</p>
        <p class="title_2">2.3 线上直播课程。</p>
        <p class="title_2">2.4 充值课时后，可按学员测评结果进行线下课程。</p>
        <p class="title_2">2.5 如遇退费情况，按月计算，退还未使用月份的会籍费。</p>
        <p class="title">课时费说明 </p>
        <p>1. 根据课程设置不同，每节课按1-4课时进行扣费。</p>
        <p>2. 自【预约课程】的课程列表中可查看单课程扣费标准。</p>
        <p>3. 充值后，先消耗正常课时，再消耗赠送课时。</p>
        <p>4. 如遇退费情况，退还未使用的正常课时，赠送课时不退。 </p>

      </div>

      <div class="button" @click="onGoNewPage('membershipCharge')">
        同意
      </div>
      </div>

    <alert ref="alertPart"></alert>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<script>
import {
  PhoneRelationShopQuery,
  CurrentPromotionsQuery,
  JsApiPrepayRequest,
  GetOpenIdAndPhoneByCode
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

let qs = require('qs');

export default {
  data() {
    return {
      openId: '',
      phone: '',
      phoneQuery: '',
      inviteCode: '',
      userList: [],
      userSelect: '',
      monthSelect: '',
      hourSelect: '',
      validitySelect: '',
      giftTips: '无赠送',
      cashTotal: '0',
      RsMonth: [],
      RsHour: [],
      RsValidity: [],
    }
  },
  mounted() {
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'membershipCharge') {
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
  },
  beforeDestroy() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert, DatePicker
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #f2f2f2;
  padding-bottom: 50px;
  overflow-y: scroll;
}

.topBC {
  height: 120px;
  width: 100%;
  background: red;
  background: url('../static/images/bc_charge.png') no-repeat center;
  text-align: center;
}

.topBC img {
  margin-top: 11px;
}

.topBC .title {
  height: 35px;
  width: 100%;
  margin-top: 15px;
}

.title span {
  display: block;
  font-size: 30px;
  text-align: center;
  color: white;
}

.title img {
  width: 28px;
  height: 35px;
  float: right;
  margin-top: -45px;
  margin-right: 95px;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}

.context {
  width: 100%;
  padding-top: 1px;
  border-radius: 10px;
  padding-bottom: 30px;
}

.context .title {
  width: 100%;
  background-color: white;
  height: 60px;
  font-size: 22px;
  line-height: 60px;
  text-align: center;
}

.context .content {
  width: 100%;
  background-color: white;
  margin-top: 3px;
  font-size: 16px;
  padding: 2% 5%;
}

.content .title_2 {
  padding-left: 10px;
}

.content .title_3 {
  padding-left: 20px;
}

.context .button {
  margin-left: 2.5%;
  width: 95%;
  border-radius: 6px;
  font-size: 22px;
  color: white;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background-color: #FFA310;
  margin-top: 20px;

}

::-webkit-input-placeholder {
  color: #ffb8d9;
}

/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
